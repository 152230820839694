import RmbOrLimit from '@/components/common/rmbOrLimit/index.vue';
import CustomStepper from '@/components/common/custom-stepper/index.vue';

export default {

    components: {
        RmbOrLimit,
        CustomStepper
    },

    props: {
        show: {
            type: Boolean,
            default: true
        },
        title: {
            type: String
        },
        pic: {
            type: String
        },
        name: {
            type: String
        },
        format: {
            type: String
        },
        skus: {
            type: Array
        },
        price: {
            type: Number
        },
        unit: {
            type: String
        },
        places: {
            type: Number
        },
        limit: {
            type: String
        },
        count: {
            type: Number
        },
        step: {
            type: Number
        },
        min: {
            type: Number
        },
        max: {
            type: Number
        },
        plusStep: {
            type: Number
        },
        minusStep: {
            type: Number
        },
    },

    data() {
        return {

        }
    },

    mounted() {

    },

    methods: {

        // 点击确定
        onConfirm() {
            // this.$emit('close')
            this.$emit('ok')
        },

        // 点击关闭
        onClose() {
            this.$emit('close')
        },

        onCountChange(val) {
            this.$emit('countChange', val);
        }
    }
}