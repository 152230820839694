import { mapState } from 'vuex';
import { Toast } from 'vant';
import Sku from './components/sku/index.vue';
import { getActDetail, getLimitBuy } from '@/service/enroll-activity/index';
import { updateAppMessageShareData } from '@/utils/wx-promise';

export default {
    name: 'retail-detail',

    components: {
        Sku,
    },

    data() {
        return {
            showLinkTip: false,
            showSku: false, // 规格展示
            data: {},
            count: 0, // 购买数量
            limitBuy: 0, // 限购数量
        };
    },

    computed: {
        ...mapState({
            userId: state => state.user.user.id,
        }),
    },

    // 监听
    watch: {},

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.getDetail();
        },

        // 获取列表
        getDetail() {
            let id = this.$route.query.id;
            getActDetail({ id: id }).then(res => {
                this.data = res;
                this.updateShareDesc(res);
            });
        },

        // 链接分享
        onInveiteLink() {
            this.showLinkTip = true;
        },

        // 立即购买
        async onBuy() {
            let { id, signupId } = this.$route.query;
            const { userId, data } = this;
            const res = await getLimitBuy({ userId, actId: signupId, productId: data.productId });
            if (res == undefined || res == null) return;
            let cou = data.restrictSum === 0 ? 999999999 : data.restrictSum - res;
            this.limitBuy = cou;
            if (this.limitBuy == 0) {
                Toast('购买次数已用完！');
                return;
            }
            this.showSku = true;
        },

        // 数量变化
        onCountChange(val) {
            let num = Number(val);
            this.count = num;
            this.data.quantity = num;
        },

        // 跳转支付
        async onConfirm() {
            const { count, data } = this;
            const { signupId } = this.$route.query;

            if (count == 0) {
                Toast('请选择购买数量！');
                return;
            }
            this.showSku = false;

            let prepayOrder = {
                products: data,
                totalPrice: count * data.price,
            };
            // 设置预订单
            await this.$store.dispatch('preorder/reset');
            this.$store.commit('preorder/setField', prepayOrder);
            this.$router.push({
                query: {
                    id: signupId,
                },
                path: '/enroll-activity/pre-order',
            });
        },

        shareUrl() {
            let { id, signupId } = this.$route.query;
            const base = location.href.slice(0, location.href.indexOf('#') + 1);
            return `${base}/enroll-activity/retail-detail?id=${id}&signupId=${signupId}`;
        },

        //  更新微信分享菜单
        updateShareDesc(data) {
            //  自定义微信分享
            updateAppMessageShareData({
                title: data.productName,
                desc: '点击链接购买',
                link: this.shareUrl(),
                imgUrl: data.thumb,
            }).catch(err => {
                console.log('微信分享err===', err);
            });
        },
    },
};
