/**
 * 金额展示
 */
export default {
    name: 'rmbOrLimit',
    props: {
        rmbValue: { // 值
            type: [Number, String],
            default: 0
        },
        rmbExtra: { // 额外内容（后面）
            type: String,
            default: ''
        },
        needFen2Yuan: { //是否需要将分转换为元
            type: Boolean,
            default: false
        },
        limitValue: { // 值
            type: [Number, String],
            default: 0
        },
        limitExtra: { // 额外内容（后面）
            type: String,
            default: ''
        },
    }
}
